@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Inter:wght@100;400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter", sans-serif;
}

.lobster {
  font-family: "Great Vibes", cursive;
}

@keyframes twinkle {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes stars {
  0%,
  25%,
  100% {
    opacity: 0;
  }

  50%,
  75% {
    opacity: 0.3;
  }
}

body {
  background: #16213d;
  scroll-behavior: smooth;
}
